<template>
    <div class="pro-page">
        <div class='pro-header'>该借款服务由{{productDetail.appName}}提供，本平台不承担任何责任</div>
        <!-- 产品卡片 -->
        <div class='pro-card'>
            <div class='pro-card-main'>
                <div class='pro-card-top'>
                    <div class='pro-card-left'>
                        <img class='pro-card-img' :src='productDetail.appLogo' alt="logo"/>
                        <div class='pro-card-text'>{{productDetail.appName}}</div>
                    </div>
                    <div class='pro-card-right'>客服电话：{{productDetail.customerNumbers}}</div>
                </div>
                <div class='pro-card-coding pro-card-loan-j' v-if='creditStatus==6'>
                    借款金额 
                </div>
                <div class='pro-card-money' v-if='creditStatus==0 || creditStatus==3 || creditStatus==6'>
                    ￥{{creditRes.applyAmt}} 
                </div>
                <div class='pro-card-money' v-else>
                    ￥{{productDetail.applyAmtMin}} ~ {{productDetail.applyAmtMax}}
                </div>
                <div class='pro-card-fot' v-if='creditStatus!=6'>
                    <div class='pro-card-fot-text'>{{productDetail.abstratInfo}}</div>
                    <div>{{productDetail.appInfo}}</div>
                </div>
                <!-- 放款中 -->
                <div v-if='creditStatus==6'>
                    <div class="pro-card-coding">
                        申请编码：{{creditRes.orderNo}}
                    </div>
                    <div class="pro-card-coding">
                        申请期限：{{creditRes.applyTime}}
                    </div>
                    <div class="pro-card-coding">
                        申请时间：{{creditRes.applyDate}}
                    </div>
                    <div class="pro-card-coding">
                        收款账户：{{creditRes.applyAccount}}
                    </div> 
                </div>
              
            </div>
           
        </div>
        <!-- 必填资料 -->
        <div class='pro-content' v-if="creditStatus==1 && creditInfo!==null  ">
            <div class='pro-content-header'>必填资料</div>
            <div class='pro-content-line' v-for="item in creditInfo" :key='item.type' @click='creditBack(item)'>
               <div class='pro-content-line-left'>{{item.name}}</div> 
               <div :class='item.status !== "UNCOMPLETED" ? "pro-content-line-right" : "pro-content-line-right-blue" '>{{item.text}}</div> 
            </div>
        </div>
       
        <div class='pro-approval'>
            <!-- 审核中 -->
            <div class='pro-approval-main' v-if='creditStatus==2'>
                <div class="pro-approval-img"></div>
                <div class="pro-approval-text">您的申请信息正在审批中…</div>
                <div class="pro-approval-result">
                    <div>预计1小时，反馈审核结果 </div>
                    <div>请留意短信通知，或在我的借款查看审核结果</div>
                    <div class="pro-approval-tel">有疑问可联系客服：<span class="pro-tel-number">{{productDetail.customerNumbers}}</span></div>
                </div>
            </div>
            <!-- 授信未通过 -->
            <div class="pro-credit" v-if='creditStatus==3'>
                <div class="pro-credit-img"></div>
                <div class='pro-credit-text'>
                    <div>很遗憾，授信未通过 </div>
                    <div>您可以去别家看看</div>
                </div>
            </div>
            <!-- 放款中 -->
            <div class="pro-loaning" v-if='creditStatus==6'>
                <div class="pro-loaning-img"></div>
                  <div class="pro-loaning-text">您的申请正在放款中…</div>
                  <div class="pro-loaning-result">
                    <div>预计1-3天内完成放款，请留意短信通知</div>
                    <div class="pro-loaning-tel">有疑问可联系客服：<span class="pro-loaning-number">{{productDetail.customerNumbers}}</span></div>
                </div>
            </div>
            <!-- 名额已满 -->
            <div class="pro-quota" v-if='creditStatus==0'>
                <div class="pro-quota-img"></div>
                <div class="pro-quota-text">今日名额已满</div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="pro-footer">
            <!-- 申请前 -->
            <div v-if='creditStatus==1'>
                <div class='pro-footer-protocol' @click="gochencked">
                    <div :class='checked==true ? "pro-icon" : "proIcon-active"'  ></div>
                    <span class='pro-protocol-text'>
                        同意相关协议<a class='pro-text'  @click="gourl">《机构授权协议》</a>
                    </span>
                    
                </div>
                <div class='pro-footer-btn' @click="backNext">下一步</div>
            </div>
           
            <div class='pro-footer-btn' v-else @click='backIndex'>去别家看看</div>
        </div>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
// import {useRouter} from 'vue-router';
import APP from '../../utils/APP'
import API from '../../server/api'
import { Toast } from 'vant'
import { routerTag} from '../../utils/index'
export default ({
    setup() {
        const state=reactive({
            checked:false,//协议选中
            productDetail:[],//卡片
            creditInfo:[],//认证信息
            protocol:[],//协议
            creditStatus:null,//授信的状态 0.已满; 1.等待授信 ;2.审批中; 3.审批拒绝 ;4.等待借款; 6，放款中
            creditRes:null
       })
        // const router = useRouter() //2、使用 路由跳转
        //初始化产品详情
        const init=async()=>{
            
            const result = await API.product({ });
            state.productDetail=result.productDetail
            state.creditInfo=result.creditInfo
            state.protocol=result.protocol
            state.creditRes=result.creditResult
            state.creditStatus=result.creditResult.creditStatus
            window.localStorage.setItem('protocol',JSON.stringify(result.protocol))
            //设置title
            APP.SET_TITLE(result.productDetail.appName)
            // window.localStorage.setItem('titleName',result.productDetail.appName)
            if(result.creditResult.orderNo!=null && result.creditResult.orderNo!=''){
                window.localStorage.setItem('orderNo',result.creditResult.orderNo)
            }

        }
        const creditBack=(item)=>{
            console.log(item ,7);
        }

       //协议选中
        const gochencked=()=>{
                    state.checked=!state.checked
        }
        //协议
        const gourl=()=>{
            const protoUrl=routerTag+"LoanProtocol"
            APP.JUMP_H5(protoUrl,'协议')
     
        }
        const backNext= async ()=>{
            const idNO=window.localStorage.getItem('appNo')
            if(state.checked==false){
                Toast('请先同意相关协议')
                return false
            }
            const result=await API.productCredit({
                appNo:idNO
            })
          
            if(result.credit){
                const AuditUrl=routerTag+"Audit"
                APP.JUMP_H5(AuditUrl,'审核')
               
            }
          
        }
        
        const backIndex=()=>{
            APP.setData(JSON.stringify(1))
            APP.JUMP_H5(routerTag)
        }
        onMounted(()=>{
             window.TDAPP.onEvent("产品详情页-进入");
            // if(state.creditStatus==1){
            //     APP.show_View('稳住，你能赢，5W额度已经在跟您招手了')
            // }
            //通知app此页面的返回至首页
            APP.Go_pageMark(JSON.stringify('product'))
            init()
        })
        return{
            ...toRefs(state),
            // init
           gochencked,
           gourl,
           creditBack,
           backNext,
           backIndex
        }
    },
})
</script>
